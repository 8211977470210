/* global window */
/* eslint react/jsx-filename-extension: 0 */
import React from "react";
import { Amplify } from "aws-amplify";
import { Route, Switch } from "react-router-dom";

import amplifyConfig from "./config/amplify";
import RecommendationDashboard from "./dashboard";

Amplify.configure(amplifyConfig);

const RoutingLogic = () => {
  return (
    <Switch>
      <Route path="/:dashboardId" component={RecommendationDashboard} />
    </Switch>
  );
};

export default RoutingLogic;
